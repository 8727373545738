import {
  IntakeCode,
  IntakeQuestionCodes,
  intakeQuestionDataByCodes,
} from "~/common/utils/consultations/intake";
import { CompletedListHospitalsAnswer } from "~/common/utils/consultations/intake/interfaces";
import {
  CompletedListMedicationsAnswer,
  ScreeningCode,
  ScreeningQuestionCodes,
  screeningQuestionDataByCodes,
  YesNoType,
} from "~/common/utils/consultations/screening";
import {
  CompletedMultiCheckAnswer,
  CompletedRadioAnswer,
} from "~/common/utils/consultations/shared";
import { DateType } from "~/patients/utils";
import { SymptomsListFormValues } from "~/patients/utils/consultations/intake";
import {
  CompletedHospitalType,
  RadioWithTextType,
  TherapistInThePastType,
} from "~/patients/utils/consultations/intakes";

export type ScreeningQuestionType = keyof typeof screeningQuestionDataByCodes;
export type IntakeQuestionType = keyof typeof intakeQuestionDataByCodes;
export interface PatientRawResponsesScreening {
  id: string;
  screenings: ScreeningRawResponse[];
}

export interface ScreeningRawResponse {
  code: ScreeningCode;
  order: number;
  answers: ScreeningAnswer[];
}

interface AnswerWithType {
  type: ResponseQuestionTypes;
}

export type RawResponsesType =
  | CompletedMultiCheckAnswer[]
  | CompletedRadioAnswer[]
  | CompletedListMedicationsAnswer[]
  | DateType[]
  | HighSchoolDetail[]
  | TertiaryEducationDetail[]
  | RadioWithTextType[]
  | HowMuchDoYouDrinkAlcohol[]
  | CompletedHospitalType[]
  | SymptomsListFormValues[];

export type ScreeningAnswer = AnswerWithType & {
  [key in ScreeningQuestionCodes]?:
    | CompletedRadioAnswer[]
    | DateType[]
    | CompletedListMedicationsAnswer[]
    | AudioResponse[]
    | CompletedMultiCheckAnswer[];
};
export interface PatientRawResponsesIntake {
  id: string;
  intakes: IntakeRawResponse[];
}

export interface IntakeRawResponse {
  code: IntakeCode;
  order: number;
  answers: IntakeAnswer[];
}

export const MULTI_SELECT = "multi_select";

export const RESPONSE_QUESTION_TYPES = {
  yes_no_with_free_text_form: "yes_no_with_free_text_form",
  long_free_text: "long_free_text",
  short_free_text: "short_free_text",
  file: "file",
  status: "status",
  weight: "weight",
  audio: "audio",
  motivation_multi_select: "motivation_multi_select",
  yes_no: "yes_no",
  yes_no_na: "yes_no_na",
  yes_no_na_with_audio_on_yes: "yes_no_na_with_audio_on_yes",
  yes_no_not_sure: "yes_no_not_sure",
  yes_no_not_sure_with_free_text: "yes_no_not_sure_with_free_text",
  relationship_radio: "relationship-radio",
  name_email_phone: "name_email_phone",
  sex_radio: "sex_radio",
  parental_marital_status_radio: "parental_marital_status_radio",
  age: "age",
  how_often: "how_often",
  how_often_2: "how_often_2",
  zero_to_five: "zero_to_five",
  multi_medication_name_dose_frequency: "multi_medication_name_dose_frequency",
  how_symptom_have_progressed_multi_select:
    "how_symptom_have_progressed_multi_select",
  symptoms_reported_multi_select: "symptoms_reported_multi_select",
  mind_playing_tricks_multi_select: "mind_playing_tricks_multi_select",
  symptoms_experienced_multi_select: "symptoms_experienced_multi_select",
  how_much_do_you_consume_marijuana: "how_much_do_you_consume_marijuana",
  how_much_do_you_drink_alcohol: "how_much_do_you_drink_alcohol",
  date: "date",
  medical_issues_multi_select: "medical_issues_multi_select",
  hospital_admissions: "hospital_admissions",
  multi_medication_in_the_past: "multi_medication_in_the_past",
  psychiatric_in_the_past: "psychiatric_in_the_past",
  therapist_in_the_past: "therapist_in_the_past",
  jobs_you_have_held_in_the_past: "jobs_you_have_held_in_the_past",
  tertiary_education_details: "tertiary_education_details",
  high_school_details: "high_school_details",
  yes_no_with_optional_free_text_form: "yes_no_with_optional_free_text_form",
};

export const AUDIO_TRANSCRIPTION = {
  in_progress: "IN_PROGRESS",
  completed: "COMPLETED",
  failed: "FAILED",
};

export type AudioTranscriptionTypes =
  (typeof AUDIO_TRANSCRIPTION)[keyof typeof AUDIO_TRANSCRIPTION];

export type ResponseQuestionTypes =
  (typeof RESPONSE_QUESTION_TYPES)[keyof typeof RESPONSE_QUESTION_TYPES];

export type IntakeAnswer = AnswerWithType & {
  [key in IntakeQuestionCodes]?:
    | CompletedRadioAnswer[]
    | DateType[]
    | CompletedListMedicationsAnswer[]
    | AudioResponse[]
    | CompletedMultiCheckAnswer[]
    | SymptomsListFormValues[]
    | YouIndicatedYouHaveSeenAPsychiatrist[]
    | TherapistInThePastType[]
    | HowMuchMarijuanaDoYouConsume[]
    | HighSchoolDetail[]
    | HowMuchDoYouDrinkAlcohol[]
    | TertiaryEducationDetail[]
    | CompletedListHospitalsAnswer[];
};

export interface AudioResponse {
  duration: number;
  path: string;
  transcription_status?: AudioTranscriptionTypes;
  transcribed_text?: string;
  transcribed_at?: string;
}

export interface YesNoWithAudioResponse {
  value: YesNoType;
  audio: AudioResponse;
}

export interface CompletedRadioAnswerWithText extends CompletedRadioAnswer {
  extra_data: string;
}

export interface TertiaryEducationDetail {
  did_you_attend: string;
  name: string;
  degree: string;
  year_started: string;
  year_ended: string | null;
  graduated: boolean;
}

export interface HighSchoolDetail {
  did_you_attend: string;
  graduation_year: string;
  what_kind_of_student_were: string;
}

export interface HowMuchDoYouDrinkAlcohol {
  how_much: string;
  how_many_days_a_week: string;
  how_many_total_drinks_in_a_week: string;
}

export interface HowMuchMarijuanaDoYouConsume {
  how_much: string;
  how_many_days_a_week: string;
}

export interface YouIndicatedYouHaveSeenAPsychiatrist {
  what_years: string;
  precipitating_event_happen_for_you_to_seek_help: string;
}
