import { useNavigate, useOutlet, useParams } from "react-router-dom";

import NavMenu from "~/common/components/ui/NavMenu";
import { useGetPatient } from "~/providers/hooks/queries/useGetPatient";
import { useUiStore } from "~/providers/stores/useUiStore";
import { getFullName } from "~/providers/utils/GetFullName";
import { PatientHeader } from "../components/patientProfile/PatientHeader";
import { getPatientProfileMenuOptions } from "../utils/getPatientProfileMenuOptions";

export const PatientProfileLayout = () => {
  const outlet = useOutlet();
  const { setSideBarOpen } = useUiStore();
  const navigate = useNavigate();

  const { patientId } = useParams();
  const { data: patient } = useGetPatient(patientId);

  if (!patient || !patientId) {
    return null;
  }

  const menuOptions = getPatientProfileMenuOptions(
    navigate,
    patient.id,
    patient.status,
  );

  return (
    <div className="flex h-screen-calculated w-full flex-col text-sm">
      <NavMenu
        headerInfo={
          <PatientHeader
            name={getFullName(patient?.name, patient?.last_name)}
          />
        }
        title={getFullName(patient?.name, patient?.last_name)}
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />

      <div className="relative h-full overflow-auto">{outlet}</div>
    </div>
  );
};
