import {
  PatientRawResponsesIntake,
  PatientRawResponsesScreening,
} from "~/providers/utils/types/rawResponsesTypes";

export const isIntakeResponses = (
  responses: PatientRawResponsesIntake | PatientRawResponsesScreening,
): responses is PatientRawResponsesIntake => {
  return "intakes" in responses;
};
