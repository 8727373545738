import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createPatientCarePlan } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useCreatePatientCarePlan = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: createPatientCarePlanMutation, isPending } = useMutation({
    mutationFn: createPatientCarePlan,
    onError: () =>
      toast.error(
        "There was an issue creating this patient Care Plan. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["listCarePlans"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["getPatientCarePlansList"],
      });
      handleCloseModal();
    },
  });

  return { createPatientCarePlanMutation, isPending };
};
