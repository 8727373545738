import { useEffect, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { SectionHeader, TopBar } from "~/common/components";
import { Patient } from "~/common/utils";
import { PaginatedServiceResponse } from "~/patients/api/axios";
import {
  PATIENT_FILTER_PARAMS,
  PATIENT_FILTER_PARAMS_VALUES,
} from "~/providers/constants/patients";
import FilterPatientsButton from "../components/FilterPatientsButton";
import Pagination from "../components/Pagination";
import PatientsTable from "../components/PatientsTable";
import SearchBar from "../components/SearchBar";
import { useGetPatients } from "../hooks/queries/useGetPatients";
import { useUiStore } from "../stores/useUiStore";
import {
  PATIENT_LIST_STATUS_FILTERS,
  PATIENTS_FILTERS,
  PatientsFilters,
} from "../utils/types";

export const Home = () => {
  const { setSideBarOpen } = useUiStore();
  const [params, setParams] = useSearchParams({
    [PATIENT_FILTER_PARAMS.patientStatus]:
      PATIENT_LIST_STATUS_FILTERS.ongoing_care,
    [PATIENT_FILTER_PARAMS.filter]: PATIENTS_FILTERS.unreviewed,
  });

  const debouncedParams = useDebounce(params, 600);

  const paramName = params.get(PATIENT_FILTER_PARAMS.name) ?? undefined;
  const [nameFilter, setNameFilter] = useState<string | undefined>(paramName);

  const [patientList, setPatientList] = useState<
    PaginatedServiceResponse<Patient[]> | undefined
  >();

  const { data: patients, isLoading } = useGetPatients(debouncedParams);

  useEffect(() => {
    if (!nameFilter) {
      params.delete(PATIENT_FILTER_PARAMS.name);
      setParams(params);
    } else {
      params.delete(PATIENT_FILTER_PARAMS.page);
      const newParams = new URLSearchParams(params);
      newParams.set(PATIENT_FILTER_PARAMS.name, nameFilter);
      setParams(newParams);
    }
  }, [nameFilter, params, setParams]);

  useEffect(() => {
    setPatientList(patients);
  }, [patients]);

  const totalPatients = !isLoading
    ? `(${patients?.total ?? 0})`
    : `(${patientList?.total ?? 0})`;

  const onFilterChange = (filter: PatientsFilters) => {
    params.delete(PATIENT_FILTER_PARAMS.page);
    params.set(PATIENT_FILTER_PARAMS.filter, filter);
    setParams(params);
  };
  const noFilterApplied = !PATIENT_FILTER_PARAMS_VALUES.some((filter) =>
    params.has(filter),
  );
  const isDisabled = noFilterApplied && !patients?.data.length;

  const noPatients = !patients?.data.length;

  return (
    <div
      className={twMerge(
        "flex h-full w-full flex-col gap-4 px-6 py-8 text-sm md:px-14",
        noPatients && "h-screen-calculated",
      )}
    >
      <div className="grid grid-cols-3">
        <div className="flex items-center gap-4">
          <TopBar handleOpenSideBar={() => setSideBarOpen(true)} />
          <SectionHeader
            className="flex
          items-center
          text-xl font-semibold xl:text-3xl"
          >{`Patients ${totalPatients}`}</SectionHeader>
        </div>
        <SearchBar
          className="w-full"
          disabled={isDisabled}
          isLoading={isLoading}
          value={nameFilter}
          placeholder="Search by patient name"
          setValue={setNameFilter}
        />
        <div className="flex items-center justify-end">
          <FilterPatientsButton
            filter={
              (params.get(PATIENT_FILTER_PARAMS.filter) as PatientsFilters) ??
              PATIENTS_FILTERS.all
            }
            onFilterChanged={onFilterChange}
          />
        </div>
      </div>
      {patients?.data && (
        <div className="flex h-full flex-col gap-6 pb-7">
          <PatientsTable patients={patients?.data} isLoading={isLoading} />
          {patients && patients.total_pages > 1 && (
            <div className="flex w-full justify-end">
              <Pagination
                totalItems={patients.total}
                currentPage={patients.page}
                totalPages={patients.total_pages}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
