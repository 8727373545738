import { Typography } from "~/common/components";
import { generateDatesRange } from "~/providers/utils";

interface ChangePlanModalDescriptionProps {
  plan: string;
  dateStarted: string;
}

export const ChangePlanModalDescription = ({
  dateStarted,
  plan,
}: ChangePlanModalDescriptionProps) => (
  <div className="flex flex-col gap-2">
    <Typography>Current plan: {plan}</Typography>
    <Typography>{generateDatesRange(dateStarted)}</Typography>
  </div>
);
