import { PropsWithChildren, ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

import { ChevronIcon, Typography } from "~/common/components";
import { DIRECTIONS } from "~/common/constants";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface ItemCardLayoutProps extends PropsWithChildren {
  title: ReactNode;
  subtitle?: string;
  description?: string;
  additionalActions?: ReactNode;
  containerClassName?: string;
}

export const ItemCardLayout = ({
  additionalActions,
  subtitle,
  title,
  children,
  description,
  containerClassName,
}: ItemCardLayoutProps) => {
  const [seeDetails, setSeeDetails] = useState(false);

  const toggleSeeDetails = () => setSeeDetails((prev) => !prev);

  return (
    <div
      className={twMerge(
        "flex flex-col gap-4 rounded-lg border border-primary-200 bg-primary-50 text-gray-70 transition-all duration-500",
        !seeDetails && "gap-0",
        containerClassName,
      )}
    >
      <button
        className={twMerge(
          "flex w-full items-center justify-between p-4 transition-all duration-300",
          seeDetails && "pb-0",
        )}
        onClick={toggleSeeDetails}
      >
        <div className="flex flex-col items-start gap-1">
          <Typography className="text-sm font-medium tracking-wide text-gray-10">
            {subtitle}
          </Typography>
          <Typography className="text-lg font-semibold capitalize">
            {title}
          </Typography>
          <Typography
            variant={TYPOGRAPHY_VARIANTS.italic}
            className="text-sm italic tracking-wide text-gray-30"
          >
            {description}
          </Typography>
        </div>
        <div className="flex items-center gap-3">
          {additionalActions}
          <div className="w-fit">
            <ChevronIcon
              direction={seeDetails ? DIRECTIONS.top : DIRECTIONS.down}
              className="h-3.5 text-gray-70 transition-transform duration-300"
            />
          </div>
        </div>
      </button>
      <div
        className={twMerge(
          "overflow-hidden transition-all duration-300",
          seeDetails
            ? "max-h-screen p-4 pt-0 opacity-100"
            : "max-h-0 opacity-0",
        )}
      >
        {children}
      </div>
    </div>
  );
};
