import { generateDatesRange } from "~/providers/utils";
import { PatientCarePlansList } from "~/providers/utils/types/carePlanTypes";
import { ItemCardLayout } from "../ItemCardLayout";
import { PriceText } from "../PriceText";
import { CarePlansTable } from "./CarePlansTable";

interface CarePlansCardProps {
  currentCarePlan: PatientCarePlansList;
  carePlans: PatientCarePlansList[];
}

export const CarePlansCard = ({
  carePlans,
  currentCarePlan,
}: CarePlansCardProps) => {
  const { name, description, price } = currentCarePlan.provider_care_plan;

  return (
    <ItemCardLayout
      title={name}
      subtitle={generateDatesRange(currentCarePlan.start_date)}
      description={description}
      additionalActions={<PriceText price={price} frequency="month" />}
    >
      <CarePlansTable carePlans={carePlans} />
    </ItemCardLayout>
  );
};
