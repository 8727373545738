import React from "react";
import { twMerge } from "tailwind-merge";

interface SearchIconProps {
  className?: string;
}
export const SearchIcon = ({ className }: SearchIconProps) => {
  return (
    <svg
      className={twMerge("h-4 w-4  text-gray-600", className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0764 15.2578C14.4025 15.5838 14.9312 15.5838 15.2573 15.2578C15.5834 14.9317 15.5834 14.403 15.2573 14.0769L14.0764 15.2578ZM11.4789 6.82418C11.4789 9.39517 9.39469 11.4794 6.82369 11.4794V13.1494C10.317 13.1494 13.1489 10.3175 13.1489 6.82418H11.4789ZM6.82369 11.4794C4.2527 11.4794 2.1685 9.39517 2.1685 6.82418H0.498496C0.498496 10.3175 3.33038 13.1494 6.82369 13.1494V11.4794ZM2.1685 6.82418C2.1685 4.25319 4.2527 2.16898 6.82369 2.16898V0.498984C3.33038 0.498984 0.498496 3.33087 0.498496 6.82418H2.1685ZM6.82369 2.16898C9.39469 2.16898 11.4789 4.25319 11.4789 6.82418H13.1489C13.1489 3.33087 10.317 0.498984 6.82369 0.498984V2.16898ZM10.1548 11.3362L14.0764 15.2578L15.2573 14.0769L11.3357 10.1553L10.1548 11.3362Z"
        fill="currentColor"
      />
    </svg>
  );
};
