import { twMerge } from "tailwind-merge";

interface RenderSeeMoreTextAnswersProps {
  seeMoreActive: boolean;
  onClick: () => void;
  text: string;
  shortTextClassName?: string;
  longTextClassName?: string;
}

export const RenderSeeMoreTextAnswers = ({
  seeMoreActive,
  onClick,
  text,
  shortTextClassName,
  longTextClassName,
}: RenderSeeMoreTextAnswersProps) =>
  text.length > 200 ? (
    <span className={twMerge(shortTextClassName)}>
      {!seeMoreActive ? `${text.substring(0, 200)}... ` : text}
      <button
        type="button"
        className="px-2 text-primary-600 underline"
        onClick={onClick}
      >
        {!seeMoreActive ? "show more" : "show less"}
      </button>
    </span>
  ) : (
    <p className={twMerge(longTextClassName)}>{text}</p>
  );
