import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { deleteCarePlan } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useDeleteCarePlan = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: deleteCarePlanMutation, isPending } = useMutation({
    mutationFn: (id: string) => deleteCarePlan(id),
    onError: () =>
      toast.error(
        "There was an issue deleting this Care Plan. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["listCarePlans"],
      });
      handleCloseModal();
    },
  });

  return { deleteCarePlanMutation, isPending };
};
