import React, { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { Button, LoadingState, SectionHeader } from "~/common/components";
import Select from "~/common/components/ui/select/Select";
import TextArea from "~/common/components/ui/Textarea";
import { BUTTON_VARIANTS } from "~/common/constants";
import {
  ADMIT_TO_PRACTICE,
  ADMIT_TO_PRACTICE_VALUES,
  REFER_PATIENT,
  REFER_PATIENT_VALUES,
} from "~/providers/constants";
import {
  useConsultationCompleteAndSign,
  useListCarePlans,
  useUpsertConsultationCompletionDetails,
} from "~/providers/hooks/queries";
import { generateCarePlanOptions } from "~/providers/utils";
import {
  CompleteAndSignFormValuesType,
  consultationCompletionDataSchema,
  ConsultationCompletionDataType,
} from "~/providers/utils/consultationRequests";
import { ConsultationCompletionDetail } from "~/providers/utils/types/patientCompleteAndSign";
import { CompleteAndSignConfirmationModal } from "./CompleteAndSignConfirmationModal";
import { CompleteAndSignField } from "./CompleteAndSignField";
import { CompleteAndSignRadioField } from "./CompleteAndSignRadioField";

interface PatientCompleteAndSignFormProps {
  patientId: string;
  completedData?: ConsultationCompletionDetail | null;
}

export const PatientCompleteAndSignForm = ({
  patientId,
  completedData,
}: PatientCompleteAndSignFormProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const toggleConfirmationModal = () =>
    setShowConfirmationModal((prev) => !prev);

  const {
    consultationCompleteAndSignMutation,
    isPending: pendingCompleteAndSign,
  } = useConsultationCompleteAndSign(patientId, toggleConfirmationModal);

  const { upsertConsultationCompletionMutation, isPending: pendingSaveData } =
    useUpsertConsultationCompletionDetails({
      patientId,
      existingConsultationDetailsData: completedData,
    });

  const { data: carePlans, isLoading: loadingCarePlans } = useListCarePlans({
    withPatients: false,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ConsultationCompletionDataType>({
    resolver: zodResolver(consultationCompletionDataSchema),
    defaultValues: {
      completeAndSign: true,
      data: {
        ...completedData,
        provider_care_plan_id: completedData?.provider_care_plan?.id,
      },
    },
  });

  const formValues = getValues();

  const onSubmit: SubmitHandler<ConsultationCompletionDataType> = (data) =>
    data.completeAndSign
      ? toggleConfirmationModal()
      : upsertConsultationCompletionMutation(data.data);

  if (loadingCarePlans) return <LoadingState className="h-screen-calculated" />;
  return (
    <form
      className="flex size-full flex-col gap-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col gap-4">
        <SectionHeader>Complete and Sign</SectionHeader>
        <CompleteAndSignField label="Diagnostic considerations">
          <TextArea
            placeholder="Add your Diagnostic considerations"
            error={errors.data?.diagnostic_considerations?.message}
            {...register("data.diagnostic_considerations")}
          />
        </CompleteAndSignField>
        <CompleteAndSignField label="Treatment plan">
          <TextArea
            placeholder="Add your Treatment plan"
            error={errors.data?.treatment_plan?.message}
            {...register("data.treatment_plan")}
          />
        </CompleteAndSignField>
        <div className="flex w-full gap-2">
          <CompleteAndSignRadioField
            label="Admit to practice"
            options={ADMIT_TO_PRACTICE_VALUES}
            register={register("data.admit_to_practice", {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value === ADMIT_TO_PRACTICE.do_not_admit &&
                resetField("data.provider_care_plan_id", undefined),
            })}
            error={errors.data?.admit_to_practice?.message}
          >
            <Select
              control={control}
              id="data.provider_care_plan_id"
              options={generateCarePlanOptions(carePlans ?? [])}
              placeholder="Select plan"
              isDisabled={
                watch("data.admit_to_practice") !== ADMIT_TO_PRACTICE.admit
              }
              error={errors.data?.provider_care_plan_id?.message}
            />
          </CompleteAndSignRadioField>
          <CompleteAndSignRadioField
            label="Refer patient"
            options={REFER_PATIENT_VALUES}
            register={register("data.refer_patient", {
              onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value === REFER_PATIENT.not_referral_needed &&
                resetField("data.refer_note", { defaultValue: null }),
            })}
            error={errors.data?.refer_patient?.message}
          >
            <TextArea
              placeholder="Add a note for the referral"
              {...register("data.refer_note")}
              error={errors.data?.refer_note?.message}
              disabled={
                watch("data.refer_patient") !== REFER_PATIENT.referral_provided
              }
            />
          </CompleteAndSignRadioField>
        </div>
      </div>

      <div className="flex w-fit gap-2 self-end">
        <Button
          type="submit"
          className={twMerge("w-fit", pendingSaveData && "w-32 justify-center")}
          size="sm"
          variant={BUTTON_VARIANTS.outline_black}
          onClick={() => setValue("completeAndSign", false)}
          isLoading={pendingSaveData}
          disabled={!isDirty || pendingSaveData}
        >
          Save changes
        </Button>
        <Button
          type="submit"
          className="w-fit"
          size="sm"
          onClick={() => setValue("completeAndSign", true)}
        >
          Complete and sign consultation
        </Button>
      </div>
      <CompleteAndSignConfirmationModal
        show={showConfirmationModal}
        onClose={toggleConfirmationModal}
        isPending={pendingCompleteAndSign}
        data={formValues.data as CompleteAndSignFormValuesType}
        onSubmit={consultationCompleteAndSignMutation}
      />
    </form>
  );
};
