import { useQuery } from "@tanstack/react-query";

import { getPatientCarePlansList } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useGetPatientCarePlansList = (patientId: string) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientCarePlansList(patientId),
    queryKey: ["getPatientCarePlansList", patientId],
  });
  return { data, isLoading };
};
