import { useState } from "react";

import { PatientMedication, PatientMedicationStatus } from "~/common/types";
import { formatMedicationName } from "~/common/utils";
import {
  generateDatesRange,
  generateDiscontinuationTitle,
  generateMedicationCardTitle,
} from "~/providers/utils";
import { generateDefaultPatientMedication } from "~/providers/utils/medications/generateDefaultPatientMedication";
import { getLastScriptNote } from "~/providers/utils/medications/getLastScriptNote";
import { DiscontinueMedicationModal } from "../DiscontinueMedicationModal";
import { UpsertMedicationModal } from "../UpsertMedicationModal";
import { MedicationCardDetails } from "./MedicationCardDetails";
import { MedicationCardLayout } from "./MedicationCardLayout";

export interface MedicationCardProps extends PatientMedication {
  status: PatientMedicationStatus;
}

export const MedicationCard = ({
  id: patientMedicationId,
  patient,
  medication,
  status,
  discontinued_internal_note,
  discontinued_message_to_patient,
  discontinued_at,
  start_date,
  patient_medications_details,
  paused_scripts_at,
  notes,
}: MedicationCardProps) => {
  const [modifyMedication, setModifyMedication] = useState(false);
  const [showDiscontinueModal, setShowDiscontinueModal] = useState(false);
  const toggleDiscontinue = () => setShowDiscontinueModal((prev) => !prev);
  const toggleModifyMedication = () => setModifyMedication((prev) => !prev);

  const medicationTitle = generateMedicationCardTitle(
    medication,
    patient_medications_details[0],
  );

  return (
    <>
      <MedicationCardLayout
        status={status}
        areButtonsDisabled={!!discontinued_at}
        title={medicationTitle}
        subtitle={generateDatesRange(start_date, discontinued_at)}
        handleDiscontinue={toggleDiscontinue}
        handleModify={toggleModifyMedication}
        pausedScriptDate={paused_scripts_at}
      >
        <MedicationCardDetails
          status={status}
          discontinuationTitle={generateDiscontinuationTitle(discontinued_at)}
          patientMedicationsDetails={patient_medications_details}
          internalNote={discontinued_internal_note}
          patientNote={discontinued_message_to_patient}
          pausedScriptDate={paused_scripts_at}
          pausedScriptNote={getLastScriptNote(notes)}
          medicationName={formatMedicationName(medication)}
        />
      </MedicationCardLayout>

      <DiscontinueMedicationModal
        show={showDiscontinueModal}
        onClose={toggleDiscontinue}
        patientId={patient.id}
        patientMedicationId={patientMedicationId}
      />

      <UpsertMedicationModal
        key={patient_medications_details[0].id}
        patientId={patient.id}
        show={modifyMedication}
        handleClose={toggleModifyMedication}
        defaultMedication={medication}
        defaultPatientMedicationId={patientMedicationId}
        defaultPatientMedicationDetails={generateDefaultPatientMedication(
          patient_medications_details[0],
        )}
      />
    </>
  );
};
