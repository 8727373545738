import { Fragment } from "react";

import { TableData } from "~/common/components";
import { intakeQuestionDataByCodes } from "~/common/utils/consultations/intake";
import { screeningQuestionDataByCodes } from "~/common/utils/consultations/screening";
import { renderQuestionTitle } from "~/common/utils/renderQuestionTitle";
import { PronounsOptions } from "~/patients";
import { formatRawResponse } from "~/providers/utils/consultationRequests/formatRawResponses";
import {
  AudioResponse,
  IntakeAnswer,
  IntakeQuestionType,
  RawResponsesType,
  RESPONSE_QUESTION_TYPES,
  ScreeningAnswer,
  ScreeningQuestionType,
  YesNoWithAudioResponse,
} from "~/providers/utils/types/rawResponsesTypes";
import { AudioAnswer } from "./rawResponses/AudioAnswer";
import { TextAnswer } from "./rawResponses/TextAnswer";
import { YesNoWithAudioAnswer } from "./rawResponses/YesNoWithAudioAnswer";

interface RawResponsesRenderProps {
  answer: ScreeningAnswer | IntakeAnswer;
  isIntake: boolean;
  pronouns?: PronounsOptions;
}

export const RawResponsesRender = ({
  answer: answerObject,
  isIntake,
  pronouns,
}: RawResponsesRenderProps) => {
  return Object.entries(answerObject).map(([questionCode, answer]) => {
    const isAnswersArray = Array.isArray(answer) && answer.length > 0;
    const questionData = isIntake
      ? intakeQuestionDataByCodes[questionCode as IntakeQuestionType]
      : screeningQuestionDataByCodes[questionCode as ScreeningQuestionType];

    const renderAnswer = (answerObject: ScreeningAnswer | IntakeAnswer) => {
      if (answerObject.type === RESPONSE_QUESTION_TYPES.audio)
        return (
          <AudioAnswer
            questionCode={questionCode}
            answers={answer as AudioResponse[]}
          />
        );

      if (
        answerObject.type ===
        RESPONSE_QUESTION_TYPES.yes_no_na_with_audio_on_yes
      )
        return (
          <YesNoWithAudioAnswer
            questionCode={questionCode}
            answers={answer as YesNoWithAudioResponse[]}
          />
        );

      return (
        <TextAnswer
          questionCode={questionCode}
          answers={formatRawResponse(
            answerObject.type,
            answer as RawResponsesType,
            questionCode,
          )}
        />
      );
    };

    return (
      <Fragment key={questionCode}>
        <TableData className="py-3 first-letter:uppercase">
          {renderQuestionTitle(questionData?.title, pronouns, questionCode)}
        </TableData>
        {isAnswersArray && (
          <TableData className="py-3 first-letter:uppercase">
            {renderAnswer(answerObject)}
          </TableData>
        )}
      </Fragment>
    );
  });
};
