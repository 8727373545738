import { Fragment, useState } from "react";

import { RenderSeeMoreTextAnswers } from "~/common/components";

interface TextAnswerProps {
  answers: string[];
  questionCode: string;
}

export const TextAnswer = ({ answers, questionCode }: TextAnswerProps) => {
  const [seeMoreActive, setSeeMoreActive] = useState(false);

  const onClickSeeMoreButton = () => {
    setSeeMoreActive((prev) => !prev);
  };

  return answers.map((answer, index) => (
    <Fragment key={`${questionCode}-${index}`}>
      <RenderSeeMoreTextAnswers
        seeMoreActive={seeMoreActive}
        onClick={onClickSeeMoreButton}
        text={answer}
        shortTextClassName="break-words"
      />
    </Fragment>
  ));
};
