import { Typography } from "~/common/components";
import { PatientStatus } from "~/common/utils";
import { PatientStatusTag } from "~/providers/components";
import { formatDate } from "~/providers/utils";

interface ConsultationHeaderProps {
  dateStarted?: string;
  dateEnded?: string;
  patientStatus: PatientStatus;
}

const DateDisplay = ({ date, label }: { date?: string; label: string }) =>
  date && (
    <Typography className="text-sm text-gray-35">
      {label} <span className="text-gray-70">{formatDate(date)}</span>
    </Typography>
  );

export const ConsultationHeader = ({
  dateEnded,
  dateStarted,
  patientStatus,
}: ConsultationHeaderProps) => (
  <div className="flex items-center gap-3">
    <div className="flex">
      <DateDisplay date={dateStarted} label="Started" />
      {dateStarted && dateEnded && <div className="mx-2 border-r" />}
      <DateDisplay date={dateEnded} label="Ended" />
    </div>
    <PatientStatusTag status={patientStatus} />
  </div>
);
