import { Table, TableHead, TableHeader } from "~/common/components";
import { PatientCarePlansList } from "~/providers/utils/types/carePlanTypes";
import { CarePlansTableRow } from "./CarePlansTableRow";

interface CarePlansTableProps {
  carePlans: PatientCarePlansList[];
}

export const CarePlansTable = ({ carePlans }: CarePlansTableProps) => (
  <div className="w-full overflow-auto rounded-xl border border-gray-300">
    <Table>
      <TableHead>
        <tr>
          <TableHeader>Start date</TableHeader>
          <TableHeader>End date</TableHeader>
          <TableHeader>Plan</TableHeader>
        </tr>
      </TableHead>
      <tbody className="bg-white text-sm text-gray-70">
        {!!carePlans.length &&
          carePlans.map((carePlan) => (
            <CarePlansTableRow key={carePlan.id} {...carePlan} />
          ))}
      </tbody>
    </Table>
  </div>
);
