import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";

import { ArchiveIcon, Button, ConfirmActionModal } from "~/common/components";
import { BUTTON_VARIANTS } from "~/common/constants";
import { daysSince } from "~/common/utils";
import { useArchiveLeadPatient } from "~/providers/hooks";
import { ROUTES } from "~/providers/router";
import { NotificationPoint } from "../icons";

export interface ConsultationsRequestsRowProps {
  id: string;
  unreviewed: boolean;
  patientName: string;
  date?: string;
  description?: string;
}

export const ConsultationsRequestsRow = ({
  id,
  unreviewed,
  patientName,
  description,
  date,
}: ConsultationsRequestsRowProps) => {
  const [showArchivePatient, setShowArchivePatient] = useState(false);
  const toggleShowArchivePatientModal = (event?: React.MouseEvent) => {
    event?.stopPropagation();
    setShowArchivePatient((prev) => !prev);
  };

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onSuccessArchive = async () => {
    toast.success(`${patientName} has been archived successfully!`);
    await queryClient.invalidateQueries({
      queryKey: ["getPatients"],
    });
  };

  const { archivePatientMutation } = useArchiveLeadPatient(onSuccessArchive);

  const handleSeePatient = () => {
    navigate(
      generatePath(ROUTES.consultationOverview, {
        patientId: id,
      }),
    );
  };

  const handleArchivePatient = () => archivePatientMutation(id);

  const daysAgo = daysSince(date);
  const dateSinceLastUpdated =
    daysAgo !== "today" && daysAgo !== "yesterday" ? `${daysAgo} ago` : daysAgo;

  return (
    <tr
      className={twMerge(
        "border-b border-gray-200  last:border-none hover:cursor-pointer hover:bg-gray-50",
        unreviewed && "font-medium",
      )}
      onClick={handleSeePatient}
    >
      <>
        <td className="flex h-full items-center gap-4 p-3 capitalize">
          <NotificationPoint
            className={unreviewed ? "text-red-500" : "text-neutral-200"}
          />
          {patientName}
        </td>
        <td className="p-3 first-letter:uppercase">{description ?? "-"}</td>
        <td className="p-3 first-letter:uppercase">
          {date ? `${dateSinceLastUpdated}` : "-"}
        </td>
        <td className="flex justify-end p-3 px-5">
          <Button
            className="flex w-fit items-center justify-center rounded-md border border-gray-30 p-2 hover:bg-gray-200"
            variant={BUTTON_VARIANTS.outline_none}
            onClick={toggleShowArchivePatientModal}
          >
            <ArchiveIcon className="size-3" />
          </Button>
        </td>
        <ConfirmActionModal
          title="Archive request"
          description="Are you sure you want to archive this request?"
          show={showArchivePatient}
          onClose={toggleShowArchivePatientModal}
          onConfirm={handleArchivePatient}
        />
      </>
    </tr>
  );
};
