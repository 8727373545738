import { Typography } from "~/common/components";

interface PriceTextProps {
  price: string;
  frequency: string;
}

export const PriceText = ({ frequency, price }: PriceTextProps) => {
  return (
    <div className="flex items-end gap-1">
      <Typography className="text-2xl text-primary-600">
        ${price}{" "}
        <span className="text-base text-primary-500">/ {frequency}</span>
      </Typography>
    </div>
  );
};
