import { api } from "~/providers/api";
import {
  PatientRawResponsesIntake,
  PatientRawResponsesScreening,
} from "~/providers/utils/types/rawResponsesTypes";

const baseUrl = "/v1/team-members";

export const getPatientRawResponsesScreening = async (patientId: string) => {
  const { data } = await api.get<PatientRawResponsesScreening>(
    `${baseUrl}/patients/${patientId}/screening-bundle/answers`,
  );
  return data;
};

export const getPatientRawResponsesIntake = async (patientId: string) => {
  const { data } = await api.get<PatientRawResponsesIntake>(
    `${baseUrl}/patients/${patientId}/intake-bundle/answers`,
  );
  return data;
};
