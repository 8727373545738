import React from "react";

import { UserIcon } from "~/common/components";
import Modal from "~/common/components/Modal";
import { formatFullName } from "~/providers/utils";
import { CarePlanWithPatients } from "~/providers/utils/types/carePlanTypes";

interface ListCarePlanMembersModalProps {
  onClose: () => void;
  show: boolean;
  carePlan?: CarePlanWithPatients;
}
export const ListCarePlanMembersModal = ({
  onClose,
  carePlan,
  show,
}: ListCarePlanMembersModalProps) => {
  const patientCarePlans = carePlan?.patient_care_plans;
  return (
    <Modal
      title={carePlan?.name}
      className="max-h-[26.6rem] overflow-auto"
      show={show}
      onClose={onClose}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          <UserIcon className="font-light" />
          <p className="text-lg font-medium text-gray-700">
            Members ({patientCarePlans?.length})
          </p>
        </div>
        <div className="flex flex-col gap-1">
          {patientCarePlans?.map(
            ({ patient: { id, name, last_name } }, index) => (
              <p className="text-gray-40" key={id}>
                {index + 1}. {formatFullName(name, last_name)}
              </p>
            ),
          )}
        </div>
      </div>
    </Modal>
  );
};
