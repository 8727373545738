import { IconProps } from "~/common/utils";

export const UserIcon = ({ className, ...props }: IconProps) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M9.50001 12.0832C12.3765 12.0832 14.7083 9.75132 14.7083 6.87484C14.7083 3.99835 12.3765 1.6665 9.50001 1.6665C6.62352 1.6665 4.29167 3.99835 4.29167 6.87484C4.29167 9.75132 6.62352 12.0832 9.50001 12.0832ZM9.50001 12.0832C4.89763 12.0832 1.16667 14.8814 1.16667 18.3332M9.50001 12.0832C14.1024 12.0832 17.8333 14.8814 17.8333 18.3332"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
