import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { completeAndSignConsultationCompletionDetails } from "~/providers/api/consultationDetails";
import { CompleteAndSignFormValuesType } from "~/providers/utils/consultationRequests/schemas";

export const useConsultationCompleteAndSign = (
  patientId: string,
  onSuccess: () => void,
) => {
  const queryClient = useQueryClient();

  const { mutate: consultationCompleteAndSignMutation, isPending } =
    useMutation({
      mutationFn: (consultationDetails: CompleteAndSignFormValuesType) =>
        completeAndSignConsultationCompletionDetails({
          patientId,
          consultationDetails,
        }),
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ["getPatient", patientId],
        });
        await queryClient.invalidateQueries({
          queryKey: ["getConsultationCompletionDetail", patientId],
        });
        toast.success("Consultation completed and signed successfully!");
        onSuccess();
      },
      onError: () =>
        toast.error(
          "We shoot trouble completing the information, is the issue persists please reload the page!",
        ),
    });

  return { consultationCompleteAndSignMutation, isPending };
};
