import { Typography } from "~/common/components";
import { TrashIcon, UserIcon } from "~/common/components/icons";
import { PencilIcon } from "~/common/components/icons/PencilIcon";
import { Tag } from "~/common/components/Tag";
import { useUpdateCarePlan } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/myCarePlans/useUpdateCarePlan";
import { CarePlanWithPatients } from "~/providers/utils/types/carePlanTypes";
import { CarePlanBoxLayout } from "./CarePlanBoxLayout";
import { ACTION_OPTIONS, ModalToOpen } from "./CarePlans";

interface CarePlanCardProps {
  carePlan: CarePlanWithPatients;
  setModalToOpen: (modalToOpen: ModalToOpen) => void;
}

export const CarePlanCard = ({
  carePlan,
  setModalToOpen,
}: CarePlanCardProps) => {
  const {
    name,
    description,
    price,
    default: isDefault,
    patient_care_plans: patients,
  } = carePlan;

  const { updateCarePlanMutation, isPending } = useUpdateCarePlan();

  return (
    <CarePlanBoxLayout>
      <div className="flex flex-col gap-1">
        <h4 className="break-words font-semibold">{name}</h4>
        <Typography className="font-normal" variant="italic">
          {description}
        </Typography>
      </div>

      <div className="flex flex-col gap-4">
        <span className="text-primary-600">
          <span className="text-xl font-semibold">{price}/</span>
          month
        </span>
        <div className="flex items-center justify-between">
          {isDefault ? (
            <Tag className="bg-primary-100 text-primary-600">Default</Tag>
          ) : (
            <button
              type="button"
              disabled={isPending}
              className="font-medium text-primary-600"
              onClick={() =>
                updateCarePlanMutation({ ...carePlan, default: true })
              }
            >
              Set as default
            </button>
          )}

          <div className="flex items-center justify-between gap-3">
            <button
              type="button"
              disabled={!patients?.length}
              className="flex flex-row items-center justify-center gap-1"
              onClick={() =>
                setModalToOpen({ carePlan, action: ACTION_OPTIONS.listUsers })
              }
            >
              <UserIcon className="size-4 stroke-2 text-gray-10" />
              <span className="text-gray-10">{patients?.length ?? 0}</span>
            </button>

            <button
              type="button"
              onClick={() =>
                setModalToOpen({ carePlan, action: ACTION_OPTIONS.upsert })
              }
            >
              <PencilIcon className="text-gray-10" />
            </button>

            <button
              type="button"
              onClick={() =>
                setModalToOpen({ carePlan, action: ACTION_OPTIONS.delete })
              }
            >
              <TrashIcon className="text-gray-10" />
            </button>
          </div>
        </div>
      </div>
    </CarePlanBoxLayout>
  );
};
