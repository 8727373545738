import { useQuery } from "@tanstack/react-query";

import { listCarePlans } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useListCarePlans = ({
  withPatients,
}: {
  withPatients: boolean;
}) => {
  const { data, isLoading } = useQuery({
    queryFn: () => listCarePlans(withPatients),
    queryKey: ["listCarePlans", withPatients],
  });
  return { data, isLoading };
};
