import { CompletedHospitalType } from "~/patients/utils/consultations/intakes";

export const formatHospitalAdmissionsAnswers = (
  answer: CompletedHospitalType[],
) =>
  answer.flatMap((ans) =>
    ans.hospitals_admissions.map(
      ({ name, reasons_for_admission, date_started, date_completed }) => {
        const period = date_completed
          ? `${date_started} - ${date_completed}`
          : `${date_started}`;
        return `${name}, ${reasons_for_admission}, ${period}`;
      },
    ),
  );
