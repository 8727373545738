import { twMerge } from "tailwind-merge";

import { SectionHeader, Typography } from "~/common/components";
import { TYPOGRAPHY_VARIANTS } from "~/common/utils";

interface CompletedConsultationFieldLayoutProps {
  title: string;
  description: string;
  extraData?: string;
  isPrimaryInformation?: boolean;
}

export const CompletedConsultationFieldLayout = ({
  title,
  description,
  extraData,
  isPrimaryInformation,
}: CompletedConsultationFieldLayoutProps) => {
  return (
    <div className="flex w-full flex-col gap-4 rounded-md border border-neutral-80 p-5">
      <SectionHeader className="font-sans text-xl">{title}</SectionHeader>
      <div
        className={twMerge(
          isPrimaryInformation &&
            "flex w-full flex-col gap-2 rounded-md bg-primary-50 p-2 text-primary-600",
        )}
      >
        <Typography
          variant={
            isPrimaryInformation
              ? TYPOGRAPHY_VARIANTS.primary
              : TYPOGRAPHY_VARIANTS.light
          }
          className={twMerge(
            "first-letter:uppercase",
            isPrimaryInformation
              ? "font-medium text-primary-600"
              : "text-gray-10",
          )}
        >
          {description}
        </Typography>
        {extraData && (
          <Typography
            variant={TYPOGRAPHY_VARIANTS.secondary}
            className="text-base italic"
          >
            {extraData}
          </Typography>
        )}
      </div>
    </div>
  );
};
