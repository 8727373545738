import { useParams } from "react-router-dom";

import { LoadingState } from "~/common/components";
import EmptyBox from "~/common/components/icons/EmptyBox";
import RawResponsesSection from "~/providers/components/consultationRequests/RawResponsesSection";
import {
  useGetPatient,
  useGetPatientRawResponsesIntake,
  useGetPatientRawResponsesScreening,
} from "~/providers/hooks/queries";
import { PatientParams } from "~/providers/utils";
import {
  patientCompletedIntake,
  patientCompletedScreening,
} from "~/providers/utils/patientStatusHistory";

export const PatientRawResponses = () => {
  const { patientId } = useParams<keyof PatientParams>() as PatientParams;
  const { data: patient } = useGetPatient(patientId);

  const rawResponsesIntakeAvailable = patientCompletedIntake(patient?.status);
  const rawResponsesScreeningAvailable = patientCompletedScreening(
    patient?.status,
  );

  const {
    data: rawResponsesScreening,
    isLoading: isLoadingScreeningResponses,
  } = useGetPatientRawResponsesScreening(
    patientId,
    rawResponsesScreeningAvailable,
  );
  const { data: rawResponsesIntake, isLoading: isLoadingIntakeResponses } =
    useGetPatientRawResponsesIntake(patientId, rawResponsesIntakeAvailable);

  if (isLoadingIntakeResponses || isLoadingScreeningResponses)
    return <LoadingState className="p-20" />;

  if (!rawResponsesScreening && !rawResponsesIntake)
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-20">
        <EmptyBox />
        <p className="text-center text-base leading-5 text-gray-60">
          No answers are available yet.
        </p>
      </div>
    );

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-8">
        {rawResponsesScreening && (
          <RawResponsesSection
            rawResponses={rawResponsesScreening}
            patient={patient}
          />
        )}
        {rawResponsesIntake && (
          <RawResponsesSection
            rawResponses={rawResponsesIntake}
            patient={patient}
          />
        )}
      </div>
    </div>
  );
};
