import { replaceUnderscores } from "~/common/utils";
import { SymptomsListFormValues } from "~/patients/utils/consultations/intake";

export const formatSymptomsExperienced = (
  answer: SymptomsListFormValues[],
): string[] =>
  answer.map((answer) =>
    Object.entries(answer)
      .filter(([_, value]) => value.toggled)
      .map(([key]) => {
        const formattedKey = replaceUnderscores(key);
        return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
      })
      .join(", "),
  );
