import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { updateCarePlan } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useUpdateCarePlan = (handleCloseModal?: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: updateCarePlanMutation, isPending } = useMutation({
    mutationFn: updateCarePlan,
    onError: () =>
      toast.error(
        "There was an issue updating this Care Plan. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["listCarePlans"],
      });
      handleCloseModal?.();
    },
  });

  return { updateCarePlanMutation, isPending };
};
