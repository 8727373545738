import { TherapistInThePastType } from "~/patients/utils/consultations/intakes";
import { formatToLocaleDateString } from "~/providers/utils/formatDate";

export const formatTherapistInThePastAnswers = (
  answer: TherapistInThePastType[],
) =>
  answer.map(
    ({ therapist_name, reason_for_therapy, date_started, date_completed }) =>
      `${therapist_name}, ${reason_for_therapy}, ${formatToLocaleDateString(
        date_started,
      )}${date_completed && ` - ${formatToLocaleDateString(date_completed)}`}`,
  );
