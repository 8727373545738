import { Fragment, useState } from "react";

import { RenderSeeMoreTextAnswers } from "~/common/components";
import AudioPlayer from "~/patients/components/ui/AudioPlayer";
import { AudioResponse } from "~/providers/utils/types/rawResponsesTypes";

interface AudioAnswerProps {
  answers: AudioResponse[];
  questionCode: string;
}

export const AudioAnswer = ({ answers, questionCode }: AudioAnswerProps) => {
  const [seeMoreActive, setSeeMoreActive] = useState(false);

  const onClickSeeMoreButton = () => {
    setSeeMoreActive((prev) => !prev);
  };

  return answers.map((answer, index) => (
    <Fragment key={`${questionCode}-${index}`}>
      <AudioPlayer
        thumbNailClassName="blue-thumb bg-primary-200"
        totalDuration={answer.duration ?? 0}
        src={answer.path}
        className="w-full border border-primary-200 bg-primary-100 p-2 text-primary-600"
        iconsClassName="text-primary-600"
      />
      {answer.transcribed_text && (
        <RenderSeeMoreTextAnswers
          seeMoreActive={seeMoreActive}
          onClick={onClickSeeMoreButton}
          text={answer.transcribed_text}
          shortTextClassName="break-words text-sm font-light text-gray-10"
          longTextClassName="text-sm font-light text-gray-10"
        />
      )}
    </Fragment>
  ));
};
