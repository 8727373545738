import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";

export const CarePlanBoxLayout = ({
  children,
  className,
}: PropsWithChildren<Styled>) => (
  <div
    className={twMerge(
      "flex h-fit max-h-52 min-h-52 flex-col justify-between rounded-lg border border-neutral-700 p-3 text-sm",
      className,
    )}
  >
    {children}
  </div>
);
