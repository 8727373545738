import { Button } from "~/common/components";
import {
  PROCEED_TO_INTAKE_ACTION,
  REFER_ACTION,
  ScreeningSubmittedConsultationsActions,
} from "../constants";
import { ProceedToIntakeIcon, ReferIcon } from "./icons";

interface AfterScreeningButtonsProps {
  toggleModal: (name: ScreeningSubmittedConsultationsActions) => void;
}

export const AfterScreeningButtons = ({
  toggleModal,
}: AfterScreeningButtonsProps) => (
  <div className="flex gap-2 whitespace-nowrap">
    <Button
      className="w-auto border border-gray-20 bg-white font-normal text-gray-700 hover:bg-neutral-20 focus:bg-neutral-20"
      size="sm"
      onClick={() => toggleModal(REFER_ACTION)}
    >
      <ReferIcon className="shrink-0 text-gray-20" />
      Refer
    </Button>
    <Button
      className="w-auto font-normal"
      size="sm"
      onClick={() => toggleModal(PROCEED_TO_INTAKE_ACTION)}
    >
      <ProceedToIntakeIcon />
      Proceed to intake
    </Button>
  </div>
);
