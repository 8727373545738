import { replaceUnderscores, TertiaryEducationTypeTypes } from "~/common/utils";
import { TertiaryEducationDetail } from "~/providers/utils/types/rawResponsesTypes";

export const formatGraduateSchoolOrCollegeAnswers = (
  answer: TertiaryEducationDetail[],
  type: TertiaryEducationTypeTypes,
) =>
  answer.map(
    ({ did_you_attend, name, degree, year_started, year_ended, graduated }) => {
      const graduationText = `I have ${
        graduated ? `graduated` : `not graduated`
      } from ${replaceUnderscores(type)}.`;
      const periodYears = year_ended
        ? `${year_started} - ${year_ended}`
        : `${year_started}`;

      return `${did_you_attend}, ${name}, ${degree}, ${periodYears}, ${graduationText}`;
    },
  );
