import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { createCarePlan } from "~/providers/api/teamMemberPaymentAndCarePlans";

export const useCreateCarePlan = (handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: createCarePlanMutation, isPending } = useMutation({
    mutationFn: createCarePlan,
    onError: () =>
      toast.error(
        "There was an issue creating this Care Plan. Please try again later.",
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["listCarePlans"],
      });
      handleCloseModal();
    },
  });

  return { createCarePlanMutation, isPending };
};
