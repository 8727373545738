import { useState } from "react";

import { RenderSeeMoreTextAnswers } from "~/common/components";
import AudioPlayer from "~/patients/components/ui/AudioPlayer";
import { YesNoWithAudioResponse } from "~/providers/utils/types/rawResponsesTypes";

interface YesNoWithAudioAnswerProps {
  answers: YesNoWithAudioResponse[];
  questionCode: string;
}

export const YesNoWithAudioAnswer = ({
  answers,
  questionCode,
}: YesNoWithAudioAnswerProps) => {
  const [seeMoreActive, setSeeMoreActive] = useState(false);

  const onClickSeeMoreButton = () => {
    setSeeMoreActive((prev) => !prev);
  };

  return answers.map((answer, index) => (
    <div className="flex flex-col gap-2 py-4" key={`${questionCode}-${index}`}>
      {answer.value && (
        <span className="first-letter:capitalize">{answer.value}</span>
      )}
      {answer.audio && (
        <>
          <AudioPlayer
            thumbNailClassName="blue-thumb bg-primary-200"
            totalDuration={answer.audio.duration ?? 0}
            src={answer.audio.path}
            className="w-full border border-primary-200 bg-primary-100 p-2 text-primary-600"
            iconsClassName="text-primary-600"
          />
          {answer.audio.transcribed_text && (
            <RenderSeeMoreTextAnswers
              seeMoreActive={seeMoreActive}
              onClick={onClickSeeMoreButton}
              text={answer.audio.transcribed_text}
              shortTextClassName="break-words text-sm font-light text-gray-10"
              longTextClassName="text-sm font-light text-gray-10"
            />
          )}
        </>
      )}
    </div>
  ));
};
