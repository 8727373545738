import { twMerge } from "tailwind-merge";

import { CancelSubmitButtons } from "~/common/components";
import Modal from "~/common/components/Modal";
import { CompleteAndSignFormValuesType } from "~/providers/utils/consultationRequests/schemas";

interface CompleteAndSignConfirmationModalProps {
  isPending: boolean;
  show: boolean;
  onClose: () => void;
  onSubmit: (data: CompleteAndSignFormValuesType) => void;
  data?: CompleteAndSignFormValuesType;
}

export const CompleteAndSignConfirmationModal = ({
  isPending,
  show,
  onClose,
  data,
  onSubmit,
}: CompleteAndSignConfirmationModalProps) => (
  <Modal
    show={show}
    title="Complete and sign consultation"
    description="Are you sure you want to complete and sign the consultation? Once confirmed, you won’t be able to make further changes"
    onClose={onClose}
  >
    <CancelSubmitButtons
      cancelClassName="w-fit"
      submitClassName={twMerge("w-fit", isPending && "w-60 justify-center")}
      containerClassName="w-fit self-end"
      submitButtonLabel="Complete and sign consultation"
      submitButtonType="button"
      handleCancel={onClose}
      isSubmitting={isPending}
      handleSubmit={() => data && onSubmit(data)}
    />
  </Modal>
);
