import { twMerge } from "tailwind-merge";

import { Tag } from "~/common/components";
import { PATIENT_STATUS } from "~/common/constants";
import { PatientStatus, replaceUnderscores } from "~/common/utils";

interface PatientStatusTagProps {
  status: PatientStatus;
}

export const PatientStatusTag = ({ status }: PatientStatusTagProps) => {
  const statusTextStyle = {
    [PATIENT_STATUS.ongoing_care]: "bg-success-200 text-success-950",
    [PATIENT_STATUS.consultation_requested]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.screening_started]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.screening_submitted]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.referred_after_screening]:
      "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.screening_completed]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.intake_started]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.intake_submitted]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.appointment_scheduled]: "bg-primary-200 text-primary-800",
    [PATIENT_STATUS.consultation_completed]: "bg-success-200 text-success-950",
  };

  return (
    <Tag className={twMerge("w-fit px-4 py-0.5", statusTextStyle[status])}>
      <p className="first-letter:uppercase">{replaceUnderscores(status)}</p>
    </Tag>
  );
};
