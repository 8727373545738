import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { TableData } from "~/common/components";
import { formatDateWithHour, Patient } from "~/common/utils";
import { ROUTES } from "../router";
import { formatFullName } from "../utils";
import { NotificationPoint } from "./icons";

interface PatientDataProps {
  patient: Patient;
}

export const PatientsTableRowData = ({ patient }: PatientDataProps) => {
  const navigate = useNavigate();

  const { last_feed: lastFeed, last_name: lastName, name: firstName } = patient;

  const handleClick = () =>
    navigate(
      generatePath(ROUTES.activityFeed, {
        patientId: patient.id,
      }),
    );

  return (
    <tr
      onClick={handleClick}
      className={twMerge(
        "transition hover:cursor-pointer hover:bg-gray-50",
        lastFeed && !lastFeed && "font-semibold",
      )}
    >
      <TableData>
        <div className="flex items-center gap-4">
          <NotificationPoint
            className={
              lastFeed && !lastFeed?.reviewed_at
                ? "text-red-500"
                : "text-neutral-200"
            }
          />
          {formatFullName(firstName, lastName)}
        </div>
      </TableData>
      <TableData>{lastFeed ? lastFeed.description : "-"}</TableData>
      <TableData>
        {lastFeed ? formatDateWithHour(lastFeed.created_at) : "-"}
      </TableData>
    </tr>
  );
};
