import { useQuery } from "@tanstack/react-query";

import { getPatientRawResponsesIntake } from "~/providers/api/patientRawResponses";

export const useGetPatientRawResponsesIntake = (
  patientId: string,
  enabled = true,
) => {
  const { data, isLoading } = useQuery({
    queryFn: () => getPatientRawResponsesIntake(patientId),
    queryKey: ["getPatientRawResponsesIntake", patientId],
    enabled: enabled && !!patientId,
  });
  return { data, isLoading };
};
