import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  createConsultationCompletionDetails,
  updateConsultationCompletionDetails,
} from "~/providers/api/consultationDetails";
import { SaveConsultationCompletionFormValuesType } from "~/providers/utils/consultationRequests/schemas";
import { ConsultationCompletionDetail } from "~/providers/utils/types/patientCompleteAndSign";

interface UseUpsertConsultationDetails {
  patientId: string;
  existingConsultationDetailsData?: ConsultationCompletionDetail | null;
}

export const useUpsertConsultationCompletionDetails = ({
  patientId,
  existingConsultationDetailsData,
}: UseUpsertConsultationDetails) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateConsultationCompletionMutation,
    isPending: pendingUpdate,
  } = useMutation({
    mutationFn: (
      consultationDetails: SaveConsultationCompletionFormValuesType,
    ) =>
      updateConsultationCompletionDetails({
        patientId,
        consultationDetails,
        consultationDetailsId: existingConsultationDetailsData?.id,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getConsultationCompletionDetail", patientId],
      });
      toast.success("Consultation details saved successfully!");
    },
    onError: () =>
      toast.error(
        "We shoot trouble saving the information, is the issue persists please reload the page!",
      ),
  });

  const {
    mutate: createConsultationCompletionMutation,
    isPending: pendingCreate,
  } = useMutation({
    mutationFn: (
      consultationDetails: SaveConsultationCompletionFormValuesType,
    ) =>
      createConsultationCompletionDetails({
        patientId,
        consultationDetails,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getConsultationCompletionDetail", patientId],
      });
      toast.success("Consultation details saved successfully!");
    },
    onError: () =>
      toast.error(
        "We shoot trouble saving the information, is the issue persists please reload the page!",
      ),
  });

  const isPending = pendingUpdate || pendingCreate;
  const upsertConsultationCompletionMutation = existingConsultationDetailsData
    ? updateConsultationCompletionMutation
    : createConsultationCompletionMutation;

  return { upsertConsultationCompletionMutation, isPending };
};
