import { CardInputsContainer } from "~/common/components";
import { useGetPaymentSettings } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useGetPaymentSettings";
import { ConsultationFeeForm } from "./ConsultationFeeForm";
import { CarePlans } from "./myCarePlans/CarePlans";
import { StripeIntegrationForm } from "./StripeIntegrationForm";

export const PaymentAndCarePlans = () => {
  const { data: providerSettings, isLoading } = useGetPaymentSettings();

  return (
    <div className="relative flex size-full flex-col items-center gap-6 text-gray-70 md:gap-8">
      <CardInputsContainer title="Intake Consultation fee / co-pay">
        <div className="flex flex-col gap-6">
          <ConsultationFeeForm
            providerSettings={providerSettings}
            isLoadingProviderSettings={isLoading}
          />
          <StripeIntegrationForm
            providerSettings={providerSettings}
            isLoadingProviderSettings={isLoading}
          />
        </div>
      </CardInputsContainer>
      <CarePlans />
    </div>
  );
};
