import { generatePath, matchPath, NavigateFunction } from "react-router-dom";

import { LEAD_PATIENT_STATUS } from "~/common/constants";
import { SectionMenuOption } from "~/common/types";
import { PatientStatus } from "~/common/utils";
import { ROUTES } from "../router";

export const getConsultationMenuOptions = (
  navigate: NavigateFunction,
  patientId: string,
  patientStatus: PatientStatus,
): SectionMenuOption[] => {
  const actualPath = window.location.pathname;

  return [
    {
      label: "Overview",
      onClick: () =>
        navigate(
          generatePath(ROUTES.consultationOverview, {
            patientId,
          }),
        ),
      current: !!matchPath(ROUTES.consultationOverview, actualPath),
      enable: true,
    },
    {
      label: "Raw responses",
      onClick: () =>
        navigate(
          generatePath(ROUTES.consultationResponses, {
            patientId,
          }),
        ),
      current: !!matchPath(ROUTES.consultationResponses, actualPath),
      enable: true,
    },
    {
      label: "Complete and sign",
      onClick: () =>
        navigate(
          generatePath(ROUTES.consultationComplete, {
            patientId,
          }),
        ),
      current: !!matchPath(ROUTES.consultationComplete, actualPath),
      enable:
        patientStatus === LEAD_PATIENT_STATUS.appointment_scheduled ||
        patientStatus === LEAD_PATIENT_STATUS.consultation_completed,
    },
  ];
};
