import { TableData } from "~/common/components";
import { formatDate } from "~/providers/utils";
import { PatientCarePlansList } from "~/providers/utils/types/carePlanTypes";

export const CarePlansTableRow = ({
  provider_care_plan,
  start_date,
  end_date,
}: PatientCarePlansList) => {
  const { name } = provider_care_plan;

  return (
    <tr className="border-b border-gray-300 py-4 last:border-none">
      <TableData className="py-4">{formatDate(start_date)}</TableData>
      <TableData className="py-4">
        {end_date ? formatDate(end_date) : "-"}
      </TableData>
      <TableData className="py-4">{name}</TableData>
    </tr>
  );
};
