import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { Button } from "~/common/components";
import Input from "~/common/components/ui/Input";
import { useUpdateConsultationFee } from "~/providers/hooks/queries/teamMemberPaymentAndCarePlans/useUpdateConsultationFee";
import { consultationFeeSchema } from "~/providers/utils/paymentAndCarePlansSchemas";
import { ProviderPaymentSettings } from "~/providers/utils/types/paymentSettingsTypes";

export type ConsultationFeeFormValuesType = z.infer<
  typeof consultationFeeSchema
>;

interface ConsultationFeeFormProps {
  isLoadingProviderSettings: boolean;
  providerSettings?: ProviderPaymentSettings;
}

export const ConsultationFeeForm = ({
  providerSettings,
  isLoadingProviderSettings,
}: ConsultationFeeFormProps) => {
  const { updateConsultationFeeMutation, isPending } =
    useUpdateConsultationFee();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<ConsultationFeeFormValuesType>({
    resolver: zodResolver(consultationFeeSchema),
    values: {
      consultation_fee: providerSettings?.default_consultation_fee ?? null,
    },
  });

  const onSubmit: SubmitHandler<ConsultationFeeFormValuesType> = (data) => {
    updateConsultationFeeMutation({
      updateConsultationFeeData: data,
      providerPaymentSettingsId: providerSettings?.id ?? "",
    });
  };

  return (
    <form
      className="flex size-full flex-col gap-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p>
        The default fee patients will pay to proceed with the intake and
        schedule a live encounter. You will have the opportunity to modify this
        value for each patient consultation.
      </p>
      {isLoadingProviderSettings ? (
        <Skeleton width={200} height={16} />
      ) : (
        <div className="flex gap-3">
          <Input
            type="currency"
            label="Default consultation fee"
            placeholder="Enter consultation fee"
            error={errors.consultation_fee?.message}
            {...register("consultation_fee", {
              setValueAs: (value) => Number(value),
            })}
          />
          <Button
            disabled={isPending || !isDirty}
            type="submit"
            size="sm"
            isLoading={isPending}
            className={twMerge(
              "h-11 w-40 self-end",
              Boolean(errors.consultation_fee) && "mt-1 self-center",
            )}
          >
            Save changes
          </Button>
        </div>
      )}
    </form>
  );
};
