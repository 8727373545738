import { INTAKE_QUESTION_CODES, TERTIARY_EDUCATION_TYPE } from "~/common/utils";
import { CompletedListMedicationsAnswer } from "~/common/utils/consultations/screening";
import {
  CompletedMultiCheckAnswer,
  CompletedRadioAnswer,
} from "~/common/utils/consultations/shared";
import { DateType } from "~/patients";
import { SymptomsListFormValues } from "~/patients/utils/consultations/intake";
import {
  CompletedHospitalType,
  RadioWithTextType,
  TherapistInThePastType,
} from "~/patients/utils/consultations/intakes";
import {
  formatDateAnswers,
  formatGraduateSchoolOrCollegeAnswers,
  formatHighSchoolAnswers,
  formatHospitalAdmissionsAnswers,
  formatHowMuchAlcohol,
  formatHowMuchMarijuanaAnswers,
  formatMedications,
  formatMultiCheckAnswers,
  formatPsychiatricInThePast,
  formatRadioAnswers,
  formatRadioWithFreeTextAnswers,
  formatSymptomsExperienced,
  formatTherapistInThePastAnswers,
} from "~/providers/utils/consultationRequests/rawAnswersFormatters";
import {
  HighSchoolDetail,
  HowMuchDoYouDrinkAlcohol,
  HowMuchMarijuanaDoYouConsume,
  RawResponsesType,
  RESPONSE_QUESTION_TYPES,
  ResponseQuestionTypes,
  TertiaryEducationDetail,
  YouIndicatedYouHaveSeenAPsychiatrist,
} from "~/providers/utils/types/rawResponsesTypes";

export const formatRawResponse = (
  type: ResponseQuestionTypes,
  answer: RawResponsesType,
  questionCode?: string,
): string[] => {
  switch (type) {
    case RESPONSE_QUESTION_TYPES.motivation_multi_select:
    case RESPONSE_QUESTION_TYPES.medical_issues_multi_select:
    case RESPONSE_QUESTION_TYPES.symptoms_reported_multi_select:
    case RESPONSE_QUESTION_TYPES.mind_playing_tricks_multi_select:
    case RESPONSE_QUESTION_TYPES.how_symptom_have_progressed_multi_select:
      return formatMultiCheckAnswers(answer as CompletedMultiCheckAnswer[]);

    case RESPONSE_QUESTION_TYPES.therapist_in_the_past:
      return formatTherapistInThePastAnswers(
        answer as TherapistInThePastType[],
      );

    case RESPONSE_QUESTION_TYPES.multi_medication_name_dose_frequency:
      return formatMedications(answer as CompletedListMedicationsAnswer[]);

    case RESPONSE_QUESTION_TYPES.how_much_do_you_drink_alcohol:
      return formatHowMuchAlcohol(answer as HowMuchDoYouDrinkAlcohol[]);

    case RESPONSE_QUESTION_TYPES.how_much_do_you_consume_marijuana:
      return formatHowMuchMarijuanaAnswers(
        answer as HowMuchMarijuanaDoYouConsume[],
      );

    case RESPONSE_QUESTION_TYPES.psychiatric_in_the_past:
      return formatPsychiatricInThePast(
        answer as YouIndicatedYouHaveSeenAPsychiatrist[],
      );

    case RESPONSE_QUESTION_TYPES.age:
    case RESPONSE_QUESTION_TYPES.date:
      return formatDateAnswers(answer as DateType[]);

    case RESPONSE_QUESTION_TYPES.yes_no_with_free_text_form:
    case RESPONSE_QUESTION_TYPES.yes_no_with_optional_free_text_form:
    case RESPONSE_QUESTION_TYPES.yes_no_not_sure_with_free_text:
      return formatRadioWithFreeTextAnswers(answer as RadioWithTextType[]);

    case RESPONSE_QUESTION_TYPES.tertiary_education_details:
      return formatGraduateSchoolOrCollegeAnswers(
        answer as TertiaryEducationDetail[],
        questionCode === INTAKE_QUESTION_CODES.college_details
          ? TERTIARY_EDUCATION_TYPE.college
          : TERTIARY_EDUCATION_TYPE.graduate_school,
      );

    case RESPONSE_QUESTION_TYPES.high_school_details:
      return formatHighSchoolAnswers(answer as HighSchoolDetail[]);

    case RESPONSE_QUESTION_TYPES.hospital_admissions:
      return formatHospitalAdmissionsAnswers(answer as CompletedHospitalType[]);

    case RESPONSE_QUESTION_TYPES.symptoms_experienced_multi_select:
      return formatSymptomsExperienced(answer as SymptomsListFormValues[]);

    default:
      return formatRadioAnswers(answer as CompletedRadioAnswer[]);
  }
};
