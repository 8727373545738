import { PATIENT_STATUS } from "~/common/constants";
import { PatientStatus } from "~/common/utils";

export const patientCompletedIntake = (status?: PatientStatus) =>
  status === PATIENT_STATUS.intake_submitted ||
  status === PATIENT_STATUS.appointment_scheduled ||
  status === PATIENT_STATUS.consultation_completed;

export const patientCompletedScreening = (status?: PatientStatus) =>
  status === PATIENT_STATUS.screening_completed ||
  status === PATIENT_STATUS.screening_submitted ||
  status === PATIENT_STATUS.intake_started ||
  patientCompletedIntake(status);
